iframe,
object,
video {
  display: none;
}

main {
  td,
  li,
  p {
    @include content-text-print;
  }
}
